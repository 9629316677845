import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Section, Button, Animate, TrailAnimate, Grid } from '../../components';
import { Home as icons } from '../../assets/images'
import './index.scss'
import { Animate as TickTock } from './animate'
import ContactForm from './ContactForm';
import { enterLeft, enterRight } from '../../components/Animate';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import useMobile from '../../hooks/useMobile';
import dayjs from 'dayjs';

export default function Home() {
    const { t } = useTranslation()
    const { isMobile } = useMobile()


    React.useEffect(() => {
        let animate: TickTock
        const clearId = setTimeout(() => {
            animate = new TickTock(isMobile ? 80 : 150, isMobile ? 4 : 8)
            animate.initialize()
        }, 500)

        return () => {
            if (clearId) {
                clearTimeout(clearId)
            }
            if (animate) {
                animate.unbind()
            }
        }
    }, [isMobile])

    return (
        <Page className='home-page' header={{ notice: [
            { expired: dayjs('2025-12-31'), content: 'BiT Global and BitGo to form multi-jurisdictional custody JV', link: 'https://blog.bitgo.com/bitgo-to-move-wbtc-to-multi-jurisdictional-custody-to-accelerate-global-expansion-plan-2ea0623fa2c8' },
            { expired: dayjs('2025-12-31'), content: '$1 billion lawsuit against Coinbase over wBTC delisting filed by Kneupper & Covey', link: 'https://kneuppercovey.com/1-billion-lawsuit-against-coinbase-over-wbtc-delisting-filed-by-kneupper-covey' }
            ] }}>
            <div className={`section-container ${isMobile ? 'middle' : 'large'} banner-bg`}>
                <canvas id="canvas" />
                <TrailAnimate className={'section banner'}>
                    <h1 className='banner-title-1'>{t('home.banner.h1.0')}</h1>
                    <h1>{t('home.banner.h1.1')}</h1>
                    <p>{t('home.banner.p')}</p>
                    <Button type='link' to='/#contact'>{t('home.banner.button')}</Button>
                </TrailAnimate>
            </div>
            <Section containerClass='section1-bg' className='section1'>
                <TrailAnimate className='left'>
                    <h2>{t('home.section1.h2.0')}</h2>
                    <h2>{t('home.section1.h2.1')}</h2>
                    <p>{t('home.section1.p')}</p>
                </TrailAnimate>
                <div className='content'>
                    <TrailAnimate>
                        {icons.section1.slice(0, 3).map(el => (
                            <div key={el.symbol} className='item'>
                                <img className='icon' src={el.icon} />
                                <span className='name'>{el.symbol}</span>
                            </div>
                        ))}
                    </TrailAnimate>
                    <TrailAnimate>
                        {icons.section1.slice(3).map(el => {
                            const isMore = el.symbol === t('home.section1.more')
                            if (isMore) {
                                return (
                                    <NavLink key={el.symbol} className={classNames('item', 'more')} to={'/coins'} onClick={() => window.scrollTo({ top: 0 })}>
                                        <img className='icon' src={el.icon} />
                                        <span className='name'>{el.symbol}</span>
                                    </NavLink>
                                )
                            }
                            return (
                                <div key={el.symbol} className='item'>
                                    <img className='icon' src={el.icon} />
                                    <span className='name'>{el.symbol}</span>
                                </div>
                            )
                        })}
                    </TrailAnimate>
                </div>
            </Section>
            <Section className='section2'>
                <h2 className='title-star-around'>{t('home.section2.h2')}</h2>
                <Grid
                    as={TrailAnimate}
                    data={icons.section2}
                    columns={isMobile ? 1 : icons.section2.length}
                    columnStretch={false}
                    speed='slow'
                    className='content'
                    itemClass='item'>
                    {(icon, i) => (
                        <Fragment key={icon}>
                            <img className='icon' src={icon} />
                            <h3>{t(`home.section2.content.${i}.h3`)}</h3>
                            <p>{t(`home.section2.content.${i}.p`)}</p>
                        </Fragment>
                    )}
                </Grid>
            </Section>
            <Section containerClass='section3-bg' className='section3'>
                <Animate createAnimate={enterLeft}>
                    <h2>{t('home.section3.h2')}</h2>
                    <p>{t('home.section3.p')}</p>
                    <Button type='link' to='/custody' onClick={() => { window.scrollTo({ top: 0 }) }}>{t('home.section3.button')}</Button>
                </Animate>
                <Animate createAnimate={enterRight}>
                    <img src={icons.section3} />
                </Animate>
            </Section>
            <Section containerClass='section4-bg' className='section4'>
                <h2 className='title-star-around'>{t('home.section4.h2')}</h2>
                <TrailAnimate className='content' itemClass='item'>
                    {icons.section4.map((icon, i) => (
                        <Fragment key={icon}>
                            <img className='icon' src={icon} />
                            <div className='text'>
                                <h3>{t(`home.section4.content.${i}.h3`)}</h3>
                                <p>{t(`home.section4.content.${i}.p`)}</p>
                            </div>
                        </Fragment>
                    ))}
                </TrailAnimate>
            </Section>
            <Section containerClass='section5-bg' className='section5' size={isMobile ? 'middle' : 'large'}>
                <Animate className='quote-bg'>
                    <p>{t('home.section5.p')}</p>
                    <img className='top' src={icons.section5.Top} />
                    <img className='bottom' src={icons.section5.Bottom} />
                </Animate>
            </Section>
            <Section id='contact' className='section6' size={isMobile ? 'middle' : 'large'}>
                <Animate createAnimate={enterLeft}>
                    <img src={icons.section6.ContactUs} />
                    <div>
                        <h2>{t('home.section6.h2')}</h2>
                        <p>{t('home.section6.p')}</p>
                    </div>
                </Animate>
                <Animate createAnimate={enterRight} className='form-wrapper'>
                    <ContactForm />
                </Animate>
            </Section>
        </Page>
    )
}