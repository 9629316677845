import MORE from './coins/more.svg'
import Flexibility from './home/section2/flexibility.svg'
import Security from './home/section2/security.svg'
import Service from './home/section2/service.svg'
import HomeCustody from './home/section3/custody.svg'
import AssetManagers from './home/section4/asset_managers.svg'
import CryptoFunds from './home/section4/crypto_funds.svg'
import Exchanges from './home/section4/exchanges.svg'
import FamilyOffices from './home/section4/family_offices.svg'
import TopQuote from './home/section5/top.svg'
import bottomQuote from './home/section5/bottom.svg'
import ContactUs from './home/section6/contact_us.svg'
import Arrow from './home/section6/arrow.svg'
import Email from './footer/email.svg'
import FooterLogo from './footer/logo.svg'
import ToTop from './footer/to_top.svg'
import HeaderMenu from './header/menu.svg'
import HeaderClose from './header/close.svg'
import HeaderLogo from './header/logo.svg'
import BankVaults from './custody/section2/bank_vaults.svg'
import CustomizableWallet from './custody/section2/customizable_wallet.svg'
import TransactionPerformanceManagement from './custody/section2/transaction_performance_management.svg'
import WithdrawalRequests from './custody/section2/withdrawal_requests.svg'
import ComprehensiveSolution from './custody/section4/comprehensive_solution.svg'
import ComprehensiveSolutionH5 from './custody/section4/comprehensive_solution_h5.svg'
import CuttingEdgeProtection from './custody/section4/cutting_edge_protection.svg'
import CuttingEdgeProtectionH5 from './custody/section4/cutting_edge_protection_h5.svg'
import OfflineStorageAssurance from './custody/section4/offline_storage_assurance.svg'
import OfflineStorageAssuranceH5 from './custody/section4/offline_storage_assurance_h5.svg'
import SecureCustodianship from './custody/section4/secure_custodianship.svg'
import SecureCustodianshipH5 from './custody/section4/secure_custodianship_h5.svg'
import AboutBanner from './about/banner.svg'
import AboutSection1 from './about/section1.svg'
import AboutSection2 from './about/section2.svg'
import ProtocolBanner from './protocol/protocol.svg'
import NewsroomBanner from './newsroom/newsroom.svg'
import BannerIcon from './joinus/banner/icon.svg'
import Compliance from './joinus/section1/compliance.svg'
import CustomerFirst from './joinus/section1/customer-first.svg'
import InnovationAndTrust from './joinus/section1/innovation_and_trust.svg'
import CareerBanner from './career/banner.svg'
import NewsItem01 from './newsroom/news-01.png'
import NewsItem02 from './newsroom/news-02.png'
import NewsItem03 from './newsroom/news-03.png'
import NotFoundIcon from './404/not_found.svg'
import Disclaimer from './home/disclaimer/disclaimer.svg'
// Tokens
import ADA from './coins/tokens/ADA.svg'
import AVAX from './coins/tokens/AVAX.svg'
import BTC from './coins/tokens/BTC.svg'
import DOGE from './coins/tokens/DOGE.svg'
import ETH from './coins/tokens/ETH.svg'
import XRP from './coins/tokens/XRP.svg'
import USDT from './coins/tokens/USDT.svg'
import USDC from './coins/tokens/USDC.svg'
import SOL from './coins/tokens/SOL.svg'
import WBTC from './coins/tokens/WBTC.svg'
import TRX from './coins/tokens/TRX.svg'
import BNB from './coins/tokens/BNB.svg'
import LINK from './coins/tokens/LINK.svg'
import DOT from './coins/tokens/DOT.svg'
import MATIC from './coins/tokens/MATIC.svg'
import SHIB from './coins/tokens/SHIB.svg'
import TON from './coins/tokens/TON.svg'
import BCH from './coins/tokens/BCH.svg'
import UNI from './coins/tokens/UNI.svg'
import NEAR from './coins/tokens/NEAR.svg'

import i18n from '../locales'

export const Tokens = {
    BTC: {
        "name": "Bitcoin",
        "symbol": "BTC",
        "icon": BTC
    },
    ETH: {
        "name": "Ethereum",
        "symbol": "ETH",
        "icon": ETH
    },
    USDT: {
        "name": "Tether USDT",
        "symbol": "USDT",
        "icon": USDT
    },
    USDC: {
        "name": "USDC",
        "symbol": "USDC",
        "icon": USDC
    },
    XRP: {
        "name": "XRP",
        "symbol": "XRP",
        "icon": XRP
    },
    SOL: {
        "name": "Solana",
        "symbol": "SOL",
        "icon": SOL
    },
    WBTC: {
        "name": "Wrapped Bitcoin",
        "symbol": "WBTC",
        "icon": WBTC
    },
    TRX: {
        "name": "TRON",
        "symbol": "TRX",
        "icon": TRX
    },
    BNB: {
        "name": "BNB",
        "symbol": "BNB",
        "icon": BNB
    },
    DOGE: {
        "name": "Dogecoin",
        "symbol": "DOGE",
        "icon": DOGE
    },
    LINK: {
        "name": "Chainlink",
        "symbol": "LINK",
        "icon": LINK
    },
    ADA: {
        "name": "Cardano",
        "symbol": "ADA",
        "icon": ADA
    },
    DOT: {
        "name": "Polkadot",
        "symbol": "DOT",
        "icon": DOT
    },
    MATIC: {
        "name": "Polygon",
        "symbol": "MATIC",
        "icon": MATIC
    },
    SHIB: {
        "name": "Shiba Inu",
        "symbol": "SHIB",
        "icon": SHIB
    },
    TON: {
        "name": "Toncoin",
        "symbol": "TON",
        "icon": TON
    },
    BCH: {
        "name": "Bitcoin Cash",
        "symbol": "BCH",
        "icon": BCH
    },
    AVAX: {
        "name": "Avalanche",
        "symbol": "AVAX",
        "icon": AVAX
    },
    UNI: {
        "name": "Uniswap",
        "symbol": "UNI",
        "icon": UNI
    },
    NEAR: {
        "name": "NEAR Protocol",
        "symbol": "NEAR",
        "icon": NEAR
    }
}

export const Header = {
    Logo: HeaderLogo,
    Close: HeaderClose,
    Menu: HeaderMenu
}

export const Footer = {
    Logo: FooterLogo,
    Email: Email,
    ToTop: ToTop
}

export const Home = {
    disclaimer: Disclaimer,
    section1: [Tokens.BTC, Tokens.ETH, Tokens.AVAX, Tokens.ADA, Tokens.XRP, Tokens.DOGE, { symbol: i18n.t('home.section1.more'), icon: MORE }],
    section2: [Security, Flexibility, Service],
    section3: HomeCustody,
    section4: [Exchanges, CryptoFunds, FamilyOffices, AssetManagers],
    section5: {
        Top: TopQuote,
        Bottom: bottomQuote
    },
    section6: {
        Arrow,
        ContactUs
    }
}

export const Custody = {
    section2: [
        BankVaults,
        CustomizableWallet,
        TransactionPerformanceManagement,
        WithdrawalRequests
    ],
    section4: {
        pc: [
            SecureCustodianship,
            CuttingEdgeProtection,
            ComprehensiveSolution,
            OfflineStorageAssurance,
        ],
        phone: [
            SecureCustodianshipH5,
            CuttingEdgeProtectionH5,
            ComprehensiveSolutionH5,
            OfflineStorageAssuranceH5,
        ]
    }
}

export const AboutUs = {
    banner: AboutBanner,
    section1: AboutSection1,
    section2: AboutSection2
}

export const Coins = {
    tokens: [
        Tokens.BTC,
        Tokens.ETH,
        Tokens.USDT,
        Tokens.USDC,
        Tokens.XRP,
        Tokens.SOL,
        Tokens.WBTC,
        Tokens.TRX,
        Tokens.BNB,
        Tokens.DOGE,
        Tokens.LINK,
        Tokens.ADA,
        Tokens.DOT,
        Tokens.MATIC,
        Tokens.SHIB,
        Tokens.TON,
        Tokens.BCH,
        Tokens.AVAX,
        Tokens.UNI,
        Tokens.NEAR,
    ]
}
export const Protocol = {
    banner: ProtocolBanner
}
export const Newsroom = {
    banner: NewsroomBanner,
    section1: [NewsItem01, NewsItem02]
}
export const JoinUs = {
    banner: BannerIcon,
    section1: [
        InnovationAndTrust,
        CustomerFirst,
        Compliance
    ],
    section2: Arrow
}

export const Career = {
    banner: CareerBanner,
}

export const NotFound = {
    icon: NotFoundIcon
}
