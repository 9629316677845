import React, { forwardRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Carousel, Dropdown, Menu, MenuProps } from "antd";
import useMobile from "../../hooks/useMobile";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from 'react-i18next';
import { Header as icons } from '../../assets/images'
import './index.scss'
import { Notice } from "./Notice";
import dayjs, { Dayjs } from 'dayjs'

function Nav(el: { title: string, children: { title: string, href: string }[] }) {
    return (
        <Dropdown
            trigger={['hover']}
            overlayClassName={'menu-popup'}
            menu={{
                items: el.children.map(item => {
                    return {
                        key: item.title,
                        label: (
                            <NavLink onClick={() => window.scrollTo({ top: 0 })} className={({ isActive }) => { return isActive ? "menu-text active" : "menu-text" }} to={item.href}>{item.title}</NavLink>
                        )
                    }
                })
            }}
            placement="bottom"
            arrow={false}
        >
            <a className="menu-item has-children">{el.title}</a>
        </Dropdown>
    )
}

export const Header = forwardRef<HTMLDivElement, { transparent?: boolean, notice?: { expired: Dayjs, content: React.ReactNode, link?: string }[] }>(({ transparent, notice }, ref) => {
    const { t } = useTranslation()
    const [selectedKeys, setSelectedKeys] = useState([])
    const navigate = useNavigate()
    const { isMobile } = useMobile()
    const [isOpen, setIsOpen] = React.useState(false)
    const location = useLocation()
    const [isScroll, setIsScroll] = React.useState(false)

    React.useEffect(() => {
        const handleScroll = () => {
            setIsScroll(window.scrollY > 80)
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menu = (t('header.menu', { returnObjects: true }) as { title: string, href?: string, children: { title: string, href: string }[] }[])

    return (
        <div ref={ref} className={`header-container ${isOpen && 'open'} ${transparent && !isScroll ? 'transparent' : ''}`}>
            <Carousel adaptiveHeight autoplay dots={false} dotPosition="left" infinite={true} autoplaySpeed={5000}>
                {notice && notice.map((item) => (
                    <>
                    {item.expired.isAfter(Date.now()) && <Notice link={item.link}>{item.content}</Notice>}
                    </>
                ))}
            {/* {notice && notice.expired.isAfter(Date.now()) && <Notice link={notice.link}>{notice.content}</Notice>} */}
            </Carousel>
            <header>
                <Link to={'/'} className="logo">
                    <img src={icons.Logo} />
                    <span>BiT Global</span>
                </Link>
                {isMobile ? (
                    <img className="menu-icon" src={isOpen ? icons.Close : icons.Menu} onClick={() => setIsOpen(!isOpen)} />
                ) : (
                    <div className="right">
                        {menu.map(el => {
                            if (el.children) {
                                return (
                                    <Nav key={el.title} {...el} />
                                )
                            }
                            return (
                                <NavLink key={el.title} onClick={() => {
                                    setIsOpen(!isOpen)
                                    window.scrollTo({ top: 0 })
                                }} className={({ isActive }) => { return isActive ? "menu-item active" : "menu-item" }} to={el.href!}>{el.title}</NavLink>
                            )
                        })}
                        <HashLink smooth className="menu-text contact-us" to={`/#contact`}>{t('header.button')}</HashLink>
                    </div>
                )}
            </header>
            {isMobile && isOpen && (
                <div className="mobile-menu">
                    <Menu
                        mode="inline"
                        selectedKeys={selectedKeys}
                        onClick={({ keyPath }) => {
                            keyPath = keyPath.reverse()
                            const findMenu = menu.find(el => el.title === keyPath[0])
                            if (findMenu?.href) {
                                navigate(findMenu.href)
                            } else if (findMenu?.children) {
                                const subMenu = findMenu?.children?.find(el => el.title === keyPath[1])
                                if (subMenu?.href) {
                                    navigate(subMenu.href)
                                }
                            }
                            setSelectedKeys([])
                            setIsOpen(false)
                            window.scrollTo({ top: 0 })
                        }}
                        items={menu.map((el, i) => {
                            return {
                                key: el.title,
                                label: el.title,
                                children: el.children?.map((item, j) => {
                                    return {
                                        key: item.title,
                                        label: item.title
                                    }
                                })
                            }
                        })}
                    />
                    <HashLink smooth className="menu-text contact-us" to={`/#contact`} onClick={() => setIsOpen(false)}>{t('header.button')}</HashLink>
                </div>
            )}
        </div>
    )
})